import { useBasket } from "hooks/firestore/simple/useBasket"
import { useOrderModeFromUrl } from "hooks/localstate/url/useOrderModeFromUrl"
import { useTableIdFromUrl } from "hooks/localstate/url/useTableIdFromUrl"

/**
 * Hook to get the orderMode in the storefront.
 *
 * The orderMode is used for various things like hiding
 * categories or product groups for some orderModes.
 *
 * The function will return the orderMode of the current basket (if it exists).
 * Else, it will return the orderMode that is specified in the URL.
 *
 * If no orderMode is found, it will return 'pickup' as a fallback.
 *
 */
export const useOrderMode = (): "pickup" | "delivery" | "selfOrder" => {
  const basket = useBasket()
  const orderModeFromUrl = useOrderModeFromUrl()
  const tableId = useTableIdFromUrl()

  // If a basket exists we return the orderMode of that basket.
  if (basket) {
    // If the basket is a delivery or pickup, we return the orderMode.
    switch (basket.orderMode) {
      case "pickup":
      case "Abholen":
        return "pickup"
      case "delivery":
      case "Lieferung":
        return "delivery"
      case "selfOrder":
        return "selfOrder"
      default:
        return "pickup"
    }
  }

  // If a tableId is specified, we return selfOrder.
  if (tableId) return "selfOrder"

  // If no orderMode is specified, we return the orderMode from the URL.
  return orderModeFromUrl || "pickup"
}
