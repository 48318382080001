import React from "react"
import ReactDOM from "react-dom"
import { X } from "styled-icons/boxicons-regular"
import { Warning } from "styled-icons/fluentui-system-filled"

interface BaseProps {
  isOpen: boolean
  title: string
  description?: string
  children: React.ReactNode
}

interface DismissableProps {
  dismissable?: true
  onClose: () => void
}

interface NonDismissableProps {
  dismissable?: false
  onClose?: never
}

type AlertDialogProps = BaseProps & (DismissableProps | NonDismissableProps)

const AlertDialog: React.FC<AlertDialogProps> = ({
  isOpen,
  title,
  description,
  dismissable = true,
  children,
  onClose,
}) => {
  if (!isOpen) return null

  return ReactDOM.createPortal(
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
      <div className="tw-bg-white tw-p-6 tw-rounded-2xl tw-max-w-md tw-w-full">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <div className="tw-flex tw-items-center">
            <Warning size={32} color="var(--danger)" className="tw-mr-3" />
            <h2 className="tw-text-xl tw-text-black tw-m-0 tw-font-bold">{title}</h2>
          </div>
          {dismissable && (
            <button onClick={onClose}>
              <X size={28} className="tw-text-gray-700" />
            </button>
          )}
        </div>
        {description && <p className="tw-mb-4">{description}</p>}
        {children}
      </div>
    </div>,
    document.body
  )
}

export default AlertDialog
