import { addDays, endOfDay, startOfDay } from "date-fns"
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"
import { getDate } from "hooks/misc/useDate"
import opening_hours, {
  argument_hash,
  nominatim_object,
  opening_hours_iterator,
  optional_conf_param,
} from "opening_hours"

class ZonedOpeningHours {
  timezone: string
  oh: opening_hours
  constructor(
    value: string,
    timezone = "Europe/Berlin",
    nominatim_object?: nominatim_object | null,
    optional_conf_param?: optional_conf_param | null
  ) {
    if (!value) {
      console.error("Opening hours would be called with a empty string, using 24/7 instead.")
    }
    this.oh = new opening_hours(value || "24/7", nominatim_object, optional_conf_param)
    this.timezone = timezone
  }

  toZoned(date: Date): Date
  toZoned(date: undefined): undefined
  toZoned(date: Date | undefined): Date | undefined
  toZoned(date: Date | undefined) {
    return date && utcToZonedTime(date.getTime(), this.timezone)
  }

  fromZoned(date: Date): Date
  fromZoned(date: undefined): undefined
  fromZoned(date: Date | undefined): Date | undefined
  fromZoned(date: Date | undefined) {
    return date && zonedTimeToUtc(date, this.timezone)
  }

  getState(date?: Date): boolean {
    return this.oh.getState(this.toZoned(date || getDate()))
  }
  getUnknown(date?: Date): boolean {
    return this.oh.getUnknown(this.toZoned(date || getDate()))
  }
  getStateString(date?: Date, past?: boolean): "open" | "unknown" | "closed" | "close" {
    return this.oh.getStateString(this.toZoned(date || getDate()), past)
  }
  getComment(date?: Date): string | undefined {
    return this.oh.getComment(this.toZoned(date || getDate()))
  }
  getNextChange(date?: Date, maxdate?: Date): Date | undefined {
    return this.fromZoned(this.oh.getNextChange(this.toZoned(date || getDate()), this.toZoned(maxdate)))
  }
  getPreviousChange(date: Date, mindate?: Date): Date | undefined {
    const minDate = this.toZoned(mindate || addDays(getDate(), -3))
    const maxDate = this.toZoned(new Date((date || getDate()).getTime() - 1))
    let result: Date | undefined = undefined
    let lastChange: Date | undefined = minDate
    do {
      lastChange = this.oh.getNextChange(lastChange, maxDate)
      result = lastChange ? lastChange : result
    } while (lastChange !== undefined)
    return this.fromZoned(result)
  }
  getMatchingRule(date?: Date): number | undefined {
    return this.oh.getMatchingRule(this.toZoned(date || getDate()))
  }
  getOpenDuration(from: Date, to: Date): [number, number] {
    return this.oh.getOpenDuration(this.toZoned(from), this.toZoned(to))
  }
  getOpenIntervals(from: Date, to: Date): [Date, Date, boolean, string | undefined][] {
    const result = this.oh.getOpenIntervals(this.toZoned(from), this.toZoned(to))
    return result.map(([from, to, unk, comment]) => [this.fromZoned(from), this.fromZoned(to), unk, comment])
  }
  getStatePair(date?: Date): [boolean, Date, boolean, string | undefined, number | undefined] {
    const result = this.oh.getStatePair(this.toZoned(date || getDate()))
    return [result[0], this.fromZoned(result[1]), result[2], result[3], result[4]]
  }
  getWarnings(): string[] {
    return this.oh.getWarnings()
  }
  isEqualTo(second_oh_object: ZonedOpeningHours, start_date: Date): boolean {
    //@ts-expect-error: The official opening_hours type definitions are wrong
    return this.oh.isEqualTo(second_oh_object.oh, start_date as opening_hours)
  }
  isWeekStable(): boolean {
    return this.oh.isWeekStable()
  }
  // Get all intervals containing date
  getMatchingIntervals(date: Date): [Date, Date, boolean, string | undefined][] {
    const zonedDate = this.toZoned(date)
    const result = this.oh
      .getOpenIntervals(startOfDay(zonedDate), endOfDay(zonedDate))
      .filter(interval => zonedDate <= interval[1] && zonedDate >= interval[0])
    return result.map(([from, to, unk, comment]) => [this.fromZoned(from), this.fromZoned(to), unk, comment])
  }
  prettifyValue(argument_hash: argument_hash): string {
    return this.oh.prettifyValue(argument_hash)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getIterator(date?: Date): opening_hours_iterator {
    throw new Error("not implemented")
  }
}

export default ZonedOpeningHours
