import { useShop } from "hooks/firestore/simple/useShop"
import { useUser } from "hooks/firestore/simple/useUser"
import { useUserId } from "hooks/localstate/auth/useUserId"

//TODO: This causes 4 additional rerenders, improve that
export function useRole() {
  const uid = useUserId()
  const user = useUser()
  const shop = useShop()
  const role = user?.getpackedAdmin
    ? "superadmin"
    : uid
      ? (shop?.users.find(userEntry => userEntry.id === uid)?.role ?? "none")
      : undefined
  return role
}
