import { where } from "firebase/firestore"
import useQuery from "hooks/firestore/core/useQuery"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { getDistributionOptionsRef } from "hooks/firestore/getRefs"
import { useShopId } from "hooks/localstate/context/useShopId"
import { DistributionOption } from "types/firestore/distributionOption"

export function useActiveDistributionOptions(): WithRef<DistributionOption>[]
export function useActiveDistributionOptions(returnLoadingState: true): {
  documents: WithRef<DistributionOption>[]
  loading: boolean
}
export function useActiveDistributionOptions(returnLoadingState?: boolean) {
  const shopId = useShopId()
  const { documents, loading } = useQuery<DistributionOption>(
    getDistributionOptionsRef(shopId),
    where("active", "==", true)
  )

  if (returnLoadingState) {
    return { documents, loading }
  }

  return documents
}
