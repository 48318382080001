import { orderBy } from "firebase/firestore"
import { getCollection } from "hooks/firestore/core/useCollection"
import useQuery from "hooks/firestore/core/useQuery"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { getDistributionOptionsRef } from "hooks/firestore/getRefs"
import { useShopId } from "hooks/localstate/context/useShopId"
import { DistributionOption } from "types/firestore/distributionOption"

// ts-prune-ignore-next
export function useDistributionOptions(): WithRef<DistributionOption>[] | undefined
export function useDistributionOptions(shopId: string): WithRef<DistributionOption>[] | undefined
export function useDistributionOptions(shopId?: string) {
  // TypeScript function overloads should prevent conditional calling of the hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realShopId = shopId || useShopId()
  const { documents } = useQuery(getDistributionOptionsRef(realShopId), orderBy("name", "asc"))
  return documents
}

// ts-prune-ignore-next
export async function getDistributionOptions(shopId: string) {
  return await getCollection(getDistributionOptionsRef(shopId))
}
